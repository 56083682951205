import React from "react"
import SEO from "../components/seo";
import { WvW } from "../components/WvW";

const WvWPage = () => (
    <>
      <SEO title="WvW" />
      <WvW />
    </>
  )

export default WvWPage