import React from "react"

import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


const getFinalPoints = (curr, payload) => {
    if (curr === Math.max.apply(Math, payload.map(function(o) { return o.value; })))
        return 5;
    if (curr === Math.min.apply(Math, payload.map(function(o) { return o.value; })))
        return 3;
    return 4;
};

const CustomTooltip = ({ active, payload, label, start_time }) => {
    if (active) {
        var skirmishTime = new Date(start_time);
        skirmishTime.setHours(skirmishTime.getHours() + (label+1) * 2);
        var skirmishHours;
        skirmishTime.getHours() < 10 ? skirmishHours = '0' + skirmishTime.getHours() : skirmishHours = skirmishTime.getHours();
        var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const date =  weekday[skirmishTime.getDay()] + ', ' + skirmishHours + ':' + skirmishTime.getMinutes() + '0';

      return (
        <div className="card px-2 border border-my_dark-lighter bg-my_dark-light text-sm text-left">
            <p className="leading-tight font-semibold">{date}</p>
            <p className="leading-tight text-my_red-light">{payload[0].value + " : +" + getFinalPoints(payload[0].value, payload)}</p>
            <p className="leading-tight text-my_blue-light">{payload[1].value + " : +" + getFinalPoints(payload[1].value, payload)}</p>
            <p className="leading-tight text-my_green-light">{payload[2].value + " : +" + getFinalPoints(payload[2].value, payload)}</p>
        </div>
      );
    }
    return null;
};


export class WvWMatchupDetails extends React.Component<any, any> {
    render() {

        const { matchup } = this.props;
        const skirmishesData = matchup.skirmishes.map(s => (
            s.scores
        ));

        const teams = ["green", "blue", "red"];

        return (
            <div className="">
                {/* <h3 className="text-center text-my_yellow bg-my_dark-light">
                    T{matchup.id.split("-")[1]} Details
                    <span className="font-serif"></span>
                </h3> */}


                <div className="md:flex pt-2">
                <div className="md:inline-flex flex-col md:w-1/5 text-center">
                    <div className="md:pb-12 inline-block mt-auto mx-auto w-full">
                        <h3 className="md:mb-4">K/D</h3>
                        {teams.map(team => (
                            <div className={"mx-3 leading-snug text-my_" + team + "-light"}>
                                {(matchup.kills[team] / matchup.deaths[team]).toFixed(2)}
                            </div>
                        ))}
                    </div>
                </div>




                <div className="md:w-4/5 text-center">
                    <h3>Skirmishes Scores</h3>
                    <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={skirmishesData} >
                        <XAxis tick={false} />
                        <YAxis tick={{fontSize: 14}} className="text-red-500" />
                        <Tooltip content={<CustomTooltip start_time={matchup.start_time} />} />
                        <Line type="monotone" dataKey="red" stroke="#dc3545" strokeWidth={2} dot={false} />
                        <Line type="monotone" dataKey="blue" stroke="rgb(55,148,255)" strokeWidth={2} dot={false} />
                        <Line type="monotone" dataKey="green" stroke="rgb(76,175,80)" strokeWidth={2} dot={false} />
                    </LineChart>
                    </ResponsiveContainer>
                </div>
                </div>



            </div>
        );
    }



    private getWidthStyle = (obj: object, team: string) => {
        var arr = Object.keys( obj ).map(function ( key ) { return obj[key]; });
        var max = Math.max.apply( null, arr );
        var divStyle = {
            width: obj[team] / max * 100 + "%"
        };
        return divStyle;
    };


    private getHeightStyle = (obj: object, team: string) => {
        var arr = Object.keys( obj ).map(function ( key ) { return obj[key]; });
        var max = Math.max.apply( null, arr );
        var divStyle = {
            height: obj[team] / max * 100 + "%"
        };
        return divStyle;
    };

}