import React from "react"

import { APIContext } from "../APIContext";

import { IoIosFlag, IoIosArrowUp, IoIosArrowForward, IoMdRemove, IoIosArrowDown } from "react-icons/io";
import { WvWMatchupDetails } from "./WvWMatchupDetails";


export class WvWMatchup extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            details: false,
        };
    }

    render() {
        const teams = ["green", "blue", "red"];
        const { matchup, getWorldName } = this.props;

        return (
            <>
                <div className="px-1 py-2 text-my_gray flex bg-my_dark border-t border-my_dark-lighter group cursor-pointer"
                onClick={this.toggleDetails} >

                    <div className="flex-auto w-full leading-tight">
                        <table className="text-left w-full">
                        <tbody>
                          <tr className="text-sm text-my_gray-darker">
                            <td className="px-3"></td>
                            <td className="w-1/3"></td>
                            <td className="w-1/3"></td>
                            <td className="w-1/3"></td>
                          </tr>

                          {teams.map(team => (
                            <tr>
                                <td className="mx-1 text-my_gray-dark">{this.getNextPlace(team)}</td>
                                <td className={"inline-flex items-center text-my_" + team + "-light"}>
                                    <div className="inline-flex">
                                        <span className="font-semibold mr-1">{getWorldName(matchup.worlds[team])}</span>
                                        <span className={"self-center md:whitespace-no-wrap text-sm " + (this.props.showSubServers ? "scale-x-on" : "scale-x-off")}>
                                            {matchup.all_worlds[team].map(subSrv => (
                                                    subSrv === matchup.worlds[team] ? null : " | " + getWorldName(subSrv)
                                            ))}
                                        </span>
                                    </div>
                                    <span className="text-sm ml-1">{matchup.all_worlds[team].includes(this.context.data.world) ? <IoIosFlag /> : null}</span>
                                </td>

                                <td>
                                    <div className="md:mr-12">
                                        <div className={"bg-my_" + team + " text-sm rounded-sm"} style={this.getWidthStyle(matchup.skirmishes[this.props.matchup.skirmishes.length - 1].scores, team)}>
                                            <span className="ml-1">{matchup.skirmishes[matchup.skirmishes.length - 1].scores[team]} (+{this.getTick(team)})</span>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className="md:mr-12">
                                        <div className={"bg-my_" + team + "  text-sm rounded-sm"} style={this.getWidthStyle(matchup.victory_points, team)}>
                                            <span className="ml-1">{matchup.victory_points[team]}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                          ))}
                        </tbody>
                        </table>
                    </div>

                    <div className="flex-auto md:mr-2 self-center  text-my_gray-darker group-hover:text-my_yellow text-3xl transition">
                        {this.state.details ? <IoIosArrowForward className="rotate" /> : <IoIosArrowForward className="rotate-rev" />}
                    </div>

                </div>

                <div className={"w-full text-my_gray-dark" + (this.state.details ? " scale-on-top" : " scale-off-top")}>
                    <WvWMatchupDetails matchup={matchup} />
                </div>
            </>
        );
    }

    private getNextPlace = (team: string) => {
        const obj = this.props.matchup.victory_points;
        var arr = Object.keys( obj ).map(function ( key ) { return obj[key]; });
        if(obj[team] === Math.max.apply( null, arr ))
            return <IoIosArrowUp />;
        if(obj[team] === Math.min.apply( null, arr ))
            return <IoIosArrowDown />;
        return <IoMdRemove />;
    };


    private getTick = (team: string) => {
        team = team.charAt(0).toUpperCase() + team.slice(1);    // Capitalize first letter
        var tick = 0;
        this.props.matchup.maps.forEach(m => {
            m.objectives.forEach(obj => {
                if (obj.owner == team)
                    tick += obj.points_tick;
            });
        });
        return tick;
    }

    private getWidthStyle = (obj: object, team: string) => {
        var arr = Object.keys( obj ).map(function ( key ) { return obj[key]; });
        var max = Math.max.apply( null, arr );
        var divStyle = {
            width: obj[team] / max * 100 + "%"
        };
        return divStyle;
    };

    private toggleDetails = () => {
        this.setState(prevState => ({
            details: !prevState.details
        }));
    };

}

WvWMatchup.contextType = APIContext;