import React from "react"

import { WvWMatchup } from "./WvWMatchup";
import { IoMdSwap } from "react-icons/io"
import { MdCheck, MdClose } from "react-icons/md";

export class WvW extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            isEU: true,
            isLoading: true,
            showSubServers: false,
            matchups: [],
            worlds: []
        };
    }

    componentDidMount() {
        this.fetchMatchups();
    }

    render() {

        const matchups = this.getMatchupFromRegion();

        return (
            <div className="md:max-w-4xl xl:max-w-6xl mx-auto mb-12 mt-2">

                <div className="flex">
                    <div className="flex-1 ">
                      <img className="inline-block h-16" src={require("../images/wvw.svg")} />
                      <p className="inline-block font-display tracking-wide text-my_green-light text-3xl align-middle">WvW</p>
                    </div>

                    <div className="self-end button mb-1 hover:bg-my_dark-light" onClick={evt => {this.setState(prevState => ({showSubServers: !prevState.showSubServers}))}}>
                        <span>{this.state.showSubServers ? <MdCheck className="text-my_green-light"/> : <MdClose className="text-my_red"/>}</span>
                        <span className="text-sm ml-1">Sub servers</span>
                    </div>

                    <div className="self-end button ml-3 mb-1 hover:bg-my_dark-light" onClick={this.toggleRegion}>
                        <IoMdSwap className={this.state.isEU ? "text-my_blue-light" : "text-my_red-light"} />
                        <span className="text-sm ml-1">{this.state.isEU ? "EU" : "NA" }</span>
                    </div>
                </div>

                {this.state.isLoading && require('./utils.tsx').loading()}

                {!this.state.isLoading &&
                    <div className="card sm:block md:flex mt-2">
                        <div className="flex-auto">
                            <div className="w-full px-3 text-my_gray flex bg-my_dark-light">
                                <table className="text-left w-full">
                                    <tbody>
                                        <tr className="text-sm text-my_gray-darker">
                                          <td className="w-1/3">Server</td>
                                          <td className="w-1/3">Skirmish (tick)</td>
                                          <td className="w-1/3">Victory Points</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {matchups.map(element => (
                                <WvWMatchup matchup={element} key={element.id} getWorldName={this.getWorldName} showSubServers={this.state.showSubServers} />
                            ))}
                        </div>

                    </div>
                }



            </div>
        );
    }

    private fetchMatchups() {
        this.setState({
            isLoading: true
        });
        fetch("https://api.guildwars2.com/v2/wvw/matches?ids=all")
            .then(responseM => responseM.json())
            .then(dataM =>
                fetch("https://api.guildwars2.com/v2/worlds?ids=all")
                .then(responseW => responseW.json())
                .then(dataW => this.setState({
                    isLoading: false,
                    matchups: dataM,
                    worlds: dataW
                }))
            )
    }

    getWorldName = (id: string) => {
        // There is only 1 result
        const res = this.state.worlds.filter(
            function(worlds){ return worlds.id == id }
        );
        if (res.length > 0)
            return res[0].name
        return "Undefined";
    }

    private toggleRegion = () => {
        this.setState(prevState => ({
            isEU: !prevState.isEU
        }));
    }

    private getMatchupFromRegion = () => {
        const region: string = this.state.isEU ? "2" : "1";
        return this.state.matchups.filter(matchup => {
            return matchup.id.startsWith(region);
        });
    }
}